import React from 'react'
import styled from 'styled-components'
import { Gadget } from 'shared/e-telligence/types'
import { RowWrapPortrait, Column, H3, P, Spacer } from 'common/ui'

const StepImage = styled.img`
  max-width: 160px;
  height: auto;
  flex: none;
`

export const presetGadgets: Gadget[] = ([
  {
    id: 'o2-regulator',
    title: 'O2 Regulator (slow breathing)',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description:
      'Breathing in slowly through your nose and out through your mouth helps to reverse the chemical reactions in your body that cause physical signs of sadness, anxiety and anger.',
  },
  {
    id: 'fire-engine',
    title: 'Fire Engine',
    emotion: ['anxiety', 'anger'],
    emotionRange: ['8-10'],
    location: ['home', 'school', 'out_appropriate'],
    description: (
      <>
        <Spacer size="m" />
        <H3>Step 1:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/fire-engine/1.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              When you’re feeling really anxious or angry, use a secret signal to show an adult that you need to use the
              fire engine gadget.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 2:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/fire-engine/2.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              With the adult’s permission, do a physical activity to burn up your anxious/angry energy. <br />
              The adult is to watch you and say nothing.
            </P>
            <P
              style={{
                marginTop: 0,
                padding: 10,
                border: '1px solid #cd693a',
                flex: 'none',
                lineHeight: '1.2',
                fontSize: '0.9em',
              }}>
              CAUTION! If an adult signals for you to use the fire engine gadget and you refuse, this person might need
              some chill out time of their own. <br />
              Try not to talk to them during this time.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 3:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/fire-engine/3.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              When you feel calmer, or when told to do so by an adult, return to the activity that upset you in the
              first place. <br />
              Use your relaxation gadgets to stay calm.
            </P>
          </Column>
        </RowWrapPortrait>
      </>
    ),
  },
  {
    id: 'thought-zapper',
    title: 'Think Helpful Thoughts',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description: (
      <>
        <Spacer size="m" />
        <H3>Step 1:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-zapper/1.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              When you detect that you’re feeling sad, angry or anxious, try to catch the unhelpful thoughts that are
              going through your head.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 2:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none" style={{ marginRight: 20 }}>
            <StepImage src={require('../assets/gadgets/steps/thought-zapper/2.png')} />
          </Column>
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-zapper/3.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              Zap these thoughts and replace them with more helpful ones. Thinking in helpful ways will make you feel
              happier, calmer and braver!
            </P>
          </Column>
        </RowWrapPortrait>
      </>
    ),
  },
  {
    id: 'thought-tracker',
    title: 'Thought Tracker',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description: (
      <>
        <Spacer size="m" />
        <H3>Step 1:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-tracker/1.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              When you detect that you’re feeling sad, angry or anxious, be on high alert for the thoughts that come
              into your head.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 2:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-tracker/2.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              Imagine the thoughts printed on spy planes or blimps that float through the air.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 3:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-tracker/3.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>Let your unhelpful thoughts float away when they are ready.</P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 4:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/thought-tracker/4.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>Refocus your attention on your breath and then on what you need to do.</P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
      </>
    ),
  },
  {
    id: 'body-scan',
    title: 'Enviro-Body Scan',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description:
      'Being aware of your body clues, thoughts and surroundings helps you to stay calm and make smart choices to boost your Bionic Powers.',
  },
  {
    id: 'count-favourites',
    title: 'Count and name your favourite things',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description:
      'Silently count to 10 in your head and name things related to your favourite hobby/interest (e.g. for a person interested in space: 1-moon-2-star- 3-rocket, etc).',
  },
  {
    id: 'physical-activity',
    title: 'Do a physical activity',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['4-7', '8-10'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'draw-or-paint',
    title: 'Draw or paint',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'gaming',
    title: 'Gaming',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'bath-or-shower',
    title: 'Have a bath or shower',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home'],
  },
  {
    id: 'drink',
    title: 'Have a drink',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out'],
  },
  {
    id: 'music-or-sounds',
    title: 'Listen to music/sounds',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'build-something',
    title: 'Make or build something',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'musical-instrument',
    title: 'Play a musical instrument',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7', '8-10'],
    location: ['home'],
  },
  {
    id: 'play-pet',
    title: 'Play with a pet',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home'],
  },
  {
    id: 'play-toy',
    title: 'Play with a toy or puzzle',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'play-clay',
    title: 'Play with clay or play dough',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'read-book',
    title: 'Read a book',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'splash-face',
    title: 'Splash your face with water',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'talk',
    title: 'Talk to someone you trust',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out_appropriate'],
  },
  {
    id: 'tear-paper',
    title: 'Tear up paper',
    emotion: ['anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home'],
  },
  {
    id: 'helpful-thoughts',
    title: 'Think Helpful Thoughts',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['anywhere'],
    description: (
      <>
        <Spacer size="m" />
        <H3>Step 1:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/helpful-thoughts/1.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              When you detect that you’re feeling sad, angry or anxious, try to catch the unhelpful ‘enemy’ thoughts
              that are going through your head.
            </P>
          </Column>
        </RowWrapPortrait>
        <Spacer size="m" />
        <H3>Step 2:</H3>
        <RowWrapPortrait paddingTop="s">
          <Column flex="none">
            <StepImage src={require('../assets/gadgets/steps/helpful-thoughts/2.png')} />
          </Column>
          <Spacer size="m" />
          <Column flex="1 1 0px">
            <P style={{ marginTop: 0 }}>
              Shoot down these thoughts and replace them with more helpful ones. Thinking in helpful ways will make you
              feel happier, calmer and braver!
            </P>
          </Column>
        </RowWrapPortrait>
      </>
    ),
  },
  {
    id: 'fidget-toy',
    title: 'Use a fidget',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home', 'school', 'out'],
  },
  {
    id: 'watch-tv',
    title: 'Watch TV',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home'],
  },
  {
    id: 'something-funny',
    title: 'Watch or listen to something funny',
    emotion: ['sadness', 'anxiety', 'anger'],
    emotionRange: ['1-3', '4-7'],
    location: ['home'],
  },
] as Gadget[]).map(preset => ({ ...preset, isPreset: true }))
