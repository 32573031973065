export const standardEmojiSet =
  '👍🏆🍉🍌🍓🥞🧀🥩🍔🍟🍕🌭🥪🌮🥣🥡🍪🍦🍫🍭🔥🥤🚜🚗🛴🚲🏊⛹️🎫📱🔫👄🧸🥇⚾🏀🏉🌏🏓🎲🎮🛸🛡🧩♟️🌀🎨🛀🛌🎟🧸💎💬🗯📖📚💰✏️🚿😃🤣😉🥰😴🥳😥😡😺🖖👏💅🦶🧔👱👧👩‍🦰🧓👴🚶🕺🏃🧑‍🤝‍🧑👨‍👩‍👧‍👦👪👀👑🎉🐎🦕🦖🐛🌸🌘⭐🍰👵🃏❓🌲🤩🤗😄'

export const bionicPowersEmojis = standardEmojiSet
export const factFileEmojis = standardEmojiSet
export const friendshipFormulaEmojis = standardEmojiSet
export const idCardEmojis = standardEmojiSet
export const emojiInputOptions = standardEmojiSet
export const secretAgentBodySignalEmojis = standardEmojiSet
export const boardGamePlayerEmojis = standardEmojiSet

export const skillTrackerEmojis = standardEmojiSet
export const skillTrackerBonusEmojis =
  '👍🏆🌮🍔🎂🏕️🏖️🏜️🏟️🏘️🏪🎡🎠🚂🚌🚜🚗🛴🚲⚓⛷️🏄🎪🛹🎫📱🔫🧸🥇⚾🏀🏈🏉🎾🌏🎳🏏🎮🛸🛡🌀🎨🎸🎈🎉🎟📽️💰😃🥳😺💅🧔👧👩‍🦰🧓👴🧑‍🤝‍🧑👨‍👩‍👧‍👦👪👓👕👗👟👑🎉🐶🦁🐎🐦🦕🦖🐠🌘⭐👵💯❓🌴`🌲😮🤩🤗'
export const skillTrackerSkillEmojis =
  '👍🍄🍕🍦🍭🏘️🏰🎠🚂🚌🚗🏍️🚲🚁🚀🛸🎆🏄🤸🤡🐣🥅🎀🎁🏀🏉🥋⛸️🎨🎸🎈🧸💎🔍💡⚔️🛡️😃😄😁🤣🙃😉😊😇🥰😝🤪🤮😰😨😔🥴🤐😶😎😟😠😡🙎😾🙀🤝👋☠️👻👽👾🤖😺💋🖐️🖖👌💪🧠👂👸🤴🧚🦹👄🧞☂️💥🐵🙈🐶🦁🐎🦄🐪🐘🐨🐓🐦🦅🦚🐸🐍🦕🦖🐬🐠🦈🐙🐛🌸🌹🦀🌘🌞⭐🌈⚡✨💞💖💜💙💚💛💔♦️🎵🔱🔴🔵🔶🔷💠◻'

export const emotionometerSituationEmojis =
  '😀😫😴😜😭🤯😡🤕👽🤖👻🤷‍♂️🏃‍♀️🗣❤💥💎🐶🐱🌻🌳🍎🍔🏀🏄‍♀️🚴‍♂️🧘‍♀️🎉🎁🏡🎠🎨🚂🚗🦋☀️🌟🌙🌈📚👩👨👴👵👩‍⚕️👨‍🏫👨‍🌾👨‍🍳👨‍🔧👩‍🔬👨‍✈️👷‍♀️👭👬🏖🏞✔❌❓'
export const emotionometerBodyClueEmojis =
  '😀🙄😥😜😭🤯😬😡😳🤢😱🏃‍♂️💃👍👎👏👂👃🦶👀🧠💬🗯💤💥💣🌸🍎🍔🤸‍♀️🧘‍♀️🚽🚀🌜🌞🌟❄⚡🔥🎵🥁👅👄🤚🦋🤒🤕💓😖'
export const emotionometerRelaxationGadgetEmojis =
  '😀😌🤖👽👻🏃‍♂️💃🛌🗣✌👍💎🐶🐱🌻🌳🍎🍔🏀🤸‍♀️🤹‍♂️🧘‍♀️♥️🏡🛸🚀🌜☀️🌟🌈⚡🎵📺💻📚✔🎨🚂🚗🦋😴🛌🏈🐻🦄🐩🐉🦕🐎🚂🔬'
